import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "auth/AuthGuard";
import Admin from "views/admin/Admin";
import AdminCreate from "views/admin/AdminCreate";
import BannerCreate from "views/banner/BannerCreate";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page/list")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard/default",
      element: <DashboardDefault />,
    }
    // {
    //     path: '/utils/util-color',
    //     element: <UtilsColor />
    // },
    // {
    //     path: '/utils/util-shadow',
    //     element: <UtilsShadow />
    // },
    // {
    //     path: '/icons/tabler-icons',
    //     element: <UtilsTablerIcons />
    // },
    // {
    //     path: '/icons/material-icons',
    //     element: <UtilsMaterialIcons />
    // },
    // {
    //     path: '/sample-page',
    //     element: <SamplePage />
    // }
  ],
};

export default MainRoutes;
