import { LoadingButton } from "@mui/lab";
import {
    Grid,
    TextField,
} from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


const AdminForm = ({ handleSubmit, tableData }) => {
    const [state, setState] = useState({...tableData, password: ''});

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
        // console.log(state);
    };


    const handleFormSubmit = async (values, { setFieldError }) => {
        setLoading(true);
        await handleSubmit(values).then((res) => {
            openSnackbar("success", res.data.message)
            setLoading(false);
            navigate("/admins");
        }).catch((e) => {
            if (e.response.status == 422) {
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])

                );

            }
            setLoading(false);
        });
    };

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={state}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    label="Name"
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                    label="Email"
                                    fullWidth
                                />
                            </Grid>


                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    label="Password"
                                    helperText={touched.password && errors.password}
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth

                                />

                            </Grid>

                        </Grid>


                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            variant="contained"
                            sx={{ my: 4 }}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default AdminForm;
