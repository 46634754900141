import axiosClient from "../axios";

export const bannerList = () => axiosClient.get("banners");

export const bannerStatusUpdate = (id) =>
  axiosClient.get(`banners-status/${id}`);

export const bannerCreate = (data) => axiosClient.post("banners", data);
export const bannerUpdate = (id, data) =>
  axiosClient.put(`banners/${id}?_method=put`, data);

export const bannerDelete = (id) => axiosClient.delete(`banners/${id}`);
