import PRODUCT_ACTION_TYPES from './product.types';

export const PRODUCT_INITIAL_STATE = {
  productLists: [],
};

export const ProductReducer = (state = PRODUCT_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_ACTION_TYPES.LOAD_PRODUCTS: {
      return { ...state, productLists: payload };
    }
    default:
      return state;
  }
};
