import styled from "@emotion/styled";
import ColorForm from "./ColorForm";
import { Box } from "@mui/system";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import SimpleCard from "ui-component/SimpleCard";
import { colorUpdate } from "services/api/color";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectColor } from "store/color/color.selector";

const ColorUpdate = () => {
  const title = "Color";
  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const { colorId } = useParams();
  const colorState = useSelector(selectColor);
  const currentColor = colorState.filter((item) => item.id == colorId);
  console.log({ colorId });
  const handleSubmit = (state) => {
    const formData = {
      english_name: state.english_name,
      arabic_name: state.arabic_name,
    };

    return colorUpdate(colorId, formData);
  };
  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            {
              name: capitalizeFirstLetter(title),
              path: "/" + title + "s",
            },
            {
              name: "Color Update",
            },
          ]}
        />
      </Box>
      <SimpleCard title="Color Form">
        <ColorForm
          handleSubmit={handleSubmit}
          tableData={{ ...currentColor[0] }}
        ></ColorForm>
        ;
      </SimpleCard>
    </Container>
  );
};
export default ColorUpdate;
