import { createSelector } from "reselect";

const selectCategoryReducer = (state) => {
  return state.category.categoryLists
};

const selectSubCategoryReducer = (state) => {
  return state.category.subCategoryLists
};

export const selectCategory = createSelector(
  [selectCategoryReducer],
  (state) => {
    return state
  }
);

export const selectSubCategory = createSelector(
  [selectSubCategoryReducer],
  (state) => {
    return state
  }
);