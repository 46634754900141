import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import Banner from "./Banner";
import BannerCreate from "./BannerCreate";
import BannerUpdate from "./BannerUpdate";
const BannerRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/banners",
      element: <Banner />,
    },
    {
      path: "/banners/create",
      element: <BannerCreate />,
    },
    {
      path: "/banners/edit/:bannerId",
      element: <BannerUpdate />,
    },
  ],
};

export default BannerRoutes;
