import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import Color from "./Color";
import ColorUpdate from "./ColorUpdate";
import ColorCreate from "./ColorCreate";

const ColorRoute = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/colors",
      element: <Color />,
    },
    {
      path: "/colors/create",
      element: <ColorCreate />,
    },
    {
      path: "/colors/update/:colorId",
      element: <ColorUpdate />,
    },
  ],
};

export default ColorRoute;
