import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helper";
import Breadcrumb from "ui-component/Breadcrumb";
import SimpleCard from "ui-component/SimpleCard";
import { selectCategory } from "store/category/category.selector";
import { categoryUpdate } from "services/api/category";
import CategoryForm from "./CategoryForm";

const CategoryUpdate = () => {
    const title = 'category';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const dispatch = useDispatch();
    const { categoryId } = useParams();

    const categoryState = useSelector(selectCategory);

    const currentCategory = categoryState.filter(item => item.id == categoryId);
    
    const handleSubmit = (state) => {

        const formData = new FormData();
        formData.append("arabic_name", state.arabic_name)
        formData.append("english_name", state.english_name)
        if(state.arabic_image instanceof File){
            formData.append("arabic_image", state.arabic_image)
        }
        if(state.english_image instanceof File){
            formData.append("english_image", state.english_image)
        }
        return categoryUpdate(categoryId, formData);
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Category Update" }]} />
            </Box>
            <SimpleCard title="Category Form">
                <CategoryForm handleSubmit={handleSubmit} tableData={{ ...currentCategory[0] }} />
            </SimpleCard>
        </Container>
    );
};

export default CategoryUpdate;
