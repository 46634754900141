// assets
import CategoryIcon from "@mui/icons-material/Category";
import ClassIcon from "@mui/icons-material/Class";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaletteIcon from "@mui/icons-material/Palette";
// ==============================|| JOB WORK MENU ITEMS ||============================== //

const productManagement = {
  id: "products-management",
  title: "Product Management",
  type: "group",
  children: [
    {
      id: "category",
      title: "Categories",
      type: "item",
      url: "/categories",
      icon: CategoryIcon,
      breadcrumbs: false,
    },
    {
      id: "sub-category",
      title: "Sub Category",
      type: "item",
      url: "/sub-categories",
      icon: ClassIcon,
      breadcrumbs: false,
    },
    {
      id: "prduct",
      title: "Product",
      type: "item",
      url: "/products",
      icon: InventoryIcon,
      breadcrumbs: false,
    },
    {
      id: "color",
      title: "Color",
      type: "item",
      url: "/colors",
      icon: PaletteIcon,
      breadcrumbs: false,
    },
  ],
};

export default productManagement;
