import COLOR_ACTION_TYPES from "./color.types";

export const COLOR_INITIAL_STATE = {
  colorLists: [],
};

export const ColorReducer = (state = COLOR_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case COLOR_ACTION_TYPES.LOAD_COLORS: {
      return { ...state, colorLists: payload };
    }
    default:
      return state;
  }
};
