import { Formik } from "formik";
import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import FileInput from "ui-component/FileInput";

import { LoadingButton } from "@mui/lab";
import useSnackbar from "hooks/useSnackbar";
import { useNavigate } from "react-router";
const BannerForm = ({ handleSubmit, tableData }) => {
  const [state, setState] = useState({ ...tableData });
  const { openSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleFileChange = (index, file) => {
    setState({ ...state, [file.id]: file.files[0] });
  };
  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
    // console.log(state);
  };
  const handleFormSubmit = async (values, { setFieldError }) => {
    setLoading(true);
    await handleSubmit({
      ...values,
      arabic_image: state.arabic_image,
      english_image: state.english_image,
    })
      .then((res) => {
        openSnackbar("success", res.data.message);
        setLoading(false);
        navigate("/banners");
      })
      .catch((e) => {
        if (e.response.status == 422) {
          Object.entries(e.response.data.result).forEach(([key, value]) =>
            setFieldError(key, value[0])
          );
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Formik initialValues={state} onSubmit={handleFormSubmit}>
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="english_title"
                  id="english_title"
                  value={values.english_title}
                  onChange={handleChange}
                  label="English Title"
                  helperText={touched.english_title && errors.english_title}
                  error={Boolean(touched.english_title && errors.english_title)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="arabic_title"
                  id="arabic_title"
                  value={values.arabic_title}
                  label="Arabic Title"
                  onChange={handleChange}
                  helperText={touched.arabic_title && errors.arabic_title}
                  error={Boolean(touched.arabic_title && errors.arabic_title)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <FileInput
                  url={values.english_image}
                  handleChange={handleFileChange}
                  fileName="english_image"
                  label="English Image"
                  helperText={touched.english_image && errors.english_image}
                  error={Boolean(touched.english_image && errors.english_image)}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <FileInput
                  url={values.arabic_image}
                  handleChange={handleFileChange}
                  helperText={touched.arabic_image && errors.arabic_image}
                  fileName="arabic_image"
                  error={Boolean(touched.arabic_image && errors.arabic_image)}
                  label="Arabic Image"
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              color="primary"
              loading={loading}
              variant="contained"
              sx={{ my: 4 }}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default BannerForm;
