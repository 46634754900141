// assets
import GroupIcon from '@mui/icons-material/Group';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

// ==============================|| JOB WORK MENU ITEMS ||============================== //

const userManagement = {
    id: 'user',
    title: 'Users',
    type: 'group',
    children: [
        // {
        //     id: 'client',
        //     title: 'User',
        //     type: 'item',
        //     url: '/users',
        //     icon: GroupIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'admin',
            title: 'Admins',
            type: 'item',
            url: '/admins',
            icon: SupervisorAccountIcon,
            breadcrumbs: false
        },
                
    ]
};

export default userManagement;
