import { styled } from "@mui/material";
import { Box } from "@mui/system";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import SimpleCard from "ui-component/SimpleCard";
import { productCreate } from "services/api/product";
import ProductForm from "./ProductForm";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { categoryList } from "services/api/category";
import { setSubCategoryList } from "store/category/category.action";
import { colorList } from "services/api/color";
import { setColorList } from "store/color/color.action";

const ProductCreate = () => {
    const title = 'product';

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const currentProduct = { english_title: "", arabic_title: "", english_desc: "", arabic_desc: "", category_id: "", color_id: "" }

    useEffect(() => {
        setLoading(true);

        try {
            categoryList(1).then((res) => {
                dispatch(setSubCategoryList(res.data.result.data));
                setLoading(false);
            });
            colorList().then((res) => {
                dispatch(setColorList(res.data.result.data));
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    }, [])

    const handleSubmit = (state, color) => {
        console.log(color)
        const formData = new FormData();
        formData.append("english_title", state.english_title)
        formData.append("arabic_title", state.arabic_title)
        formData.append("english_desc", state.english_desc)
        formData.append("arabic_desc", state.arabic_desc)
        formData.append("category_id", state.category_id)
        color.map((item, index) => {
            formData.append(`color[${index}][id]`, item.color_id)
            formData.append(`color[${index}][file][2d][0]`, item.image)
            formData.append(`color[${index}][file][3d][0]`, item.file)
        })
        
        return productCreate(formData);
    };


    return (
        // <h1>asdasd</h1>
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Product Create" }]} />
            </Box>
             <SimpleCard title="product Form">
                 <ProductForm handleSubmit={handleSubmit} tableData={currentProduct} />
             </SimpleCard>
         </Container>
    );
};

export default ProductCreate;
