import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'auth/AuthGuard';
import SubCategory from './SubCategory';
import SubCategoryCreate from './SubCategoryCreate';
import SubCategoryUpdate from './SubCategoryUpdate';


// ==============================|| MAIN ROUTING ||============================== //

const SubCategoryRoutes = {
    path: '/',
    element: <AuthGuard> <MainLayout /> </AuthGuard>,
    children: [
        {
            path: '/sub-categories',
            element: <SubCategory/>
        },
        {
            path: '/sub-categories/create',
            element: <SubCategoryCreate/>
        },
        { 
          path: '/sub-categories/edit/:subCategoryId', 
          element: <SubCategoryUpdate />
        }

        
    ]
};

export default SubCategoryRoutes;
