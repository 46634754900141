import Datatable from "ui-component/tables/Datatable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  bannerDelete,
  bannerList,
  bannerStatusUpdate,
} from "services/api/banner";
import { useState } from "react";

import { setBannerList } from "store/banner/banner.action";
import { selectBanner } from "store/banner/banner.selector";
import { isObjectEmpty } from "utils/helper";
import { useNavigate } from "react-router";
import SimpleSwitch from "ui-component/SimpleSwitch";
import { IconButton } from "@mui/material";

import useAlert from "hooks/useAlert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AvatarImage from "ui-component/Avatar";
import useSnackbar from "hooks/useSnackbar";

const Banner = () => {
  const title = "Banners";

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { updateState } = useAlert();

  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    try {
      bannerList().then((res) => {
        dispatch(setBannerList(res.data.result.data));
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleEdit = (event, row) => {
    const url = "edit/" + row.rowData[0];
    navigate(url);
  };

  const handleDelete = (event, row) => {
    updateState("Banner Delete", () => deleteHandler(row["rowData"][0]));
  };
  const deleteHandler = (id) => {
    try {
      bannerDelete(id)
        .then((res) => {
          bannerList().then((res) => {
            dispatch(setBannerList(res.data.result.data));
          });
          openSnackbar("success", res.data.message);
        })
        .catch((error) => {
          openSnackbar("error", error.data.message);
        });
    } catch (e) {
      // console.log(e)
    }
  };
  const handleStatusUpdate = (id, value) => {
    try {
      bannerStatusUpdate(id)
        .then((res) => {
          bannerList().then((res) => {
            dispatch(setBannerList(res.data.result.data));
          });
          openSnackbar("success", res.data.message);
        })
        .catch((error) => {
          openSnackbar("error", error.data.message);
        });
    } catch (e) {
      // console.log(e)
    }
  };
  const banners = useSelector(selectBanner);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "arabic_title",
      label: "Arablic Name",
    },
    {
      name: "english_title",
      label: "English Name",
    },
    {
      name: "arabic_image",
      label: "Arabic Image",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            <AvatarImage
              id={tableMeta["rowData"][0]}
              alt="Arabic Image"
              src={valueData}
              sx={{ width: 50, height: 50 }}
            />
          );
          // return <Avatar id={tableMeta["rowData"][0]} />;
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      name: "english_image",
      label: "English Image",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            <AvatarImage
              id={tableMeta["rowData"][0]}
              alt="English Image"
              src={valueData}
              sx={{ width: 50, height: 50 }}
            />
          );
        },
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          let value = valueData;
          console.log(value);
          return (
            <SimpleSwitch
              id={tableMeta["rowData"][0]}
              value={value}
              changeHandler={(id, value) => handleStatusUpdate(id, value)}
            />
          );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      name: "created_at",
      label: "Create Date",
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleEdit(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];
  const data = isObjectEmpty(banners)
    ? []
    : banners.map((item, index) => {
        return [
          item.id,
          (index += 1),
          item.arabic_title,
          item.english_title,
          item.arabic_image,
          item.english_image,
          item.is_active,
          item.created_at,
        ];
      });
  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  // console.log(data);
  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => navigate("/banners/create")}
    />
  );
};

export default Banner;
