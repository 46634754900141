import { Box } from "@mui/system";
import { capitalizeFirstLetter } from "utils/helper";
import Breadcrumb from "ui-component/Breadcrumb";
import SimpleCard from "ui-component/SimpleCard";
import ColorForm from "./ColorForm";
import { styled } from "@mui/material";
import { colorCreate } from "services/api/color";
import BannerForm from "views/banner/BannerForm";

const ColorCreate = () => {
  const title = "color";
  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const currentColor = { arabic_name: "", english_name: "" };

  const handleSubmit = (state) => {
    const formData = {
      arabic_name: state.arabic_name,
      english_name: state.english_name,
    };
    return colorCreate(formData);
  };
  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            {
              name: capitalizeFirstLetter(title),
              path: "/" + title + "s",
            },
            {
              name: "Color Create",
            },
          ]}
        />
      </Box>
      <SimpleCard title="Color Form">
        <ColorForm handleSubmit={handleSubmit} tableData={currentColor} />
      </SimpleCard>
    </Container>
  );
};
export default ColorCreate;
