import axiosClient from "../axios";

export const adminList = () => axiosClient.get('admins');
export const adminCreate = (data) => axiosClient.post('admins', data);
export const adminUpdate = (id, data) => axiosClient.put(`admins/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const adminsUpdateStatus = (id) => axiosClient.get(`admins-status/${id}`);
export const adminsDelete = (id) => axiosClient.delete(`admins/${id}`);
