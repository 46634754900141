import { styled } from "@mui/material";
import { Box } from "@mui/system";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import SimpleCard from "ui-component/SimpleCard";
import { categoryCreate } from "services/api/category";
import CategoryForm from "./CategoryForm";

const CategoryCreate = () => {
    const title = 'category';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const currentCategory = { arabic_name: "", english_name: "", arabic_image: "", english_image: "" }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("arabic_name", state.arabic_name)
        formData.append("english_name", state.english_name)
        formData.append("arabic_image", state.arabic_image)
        formData.append("english_image", state.english_image)
        
        return categoryCreate(formData);
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Category Create" }]} />
            </Box>
             <SimpleCard title="Category Form">
                 <CategoryForm handleSubmit={handleSubmit} tableData={currentCategory} />
             </SimpleCard>
         </Container>
    );
};

export default CategoryCreate;
