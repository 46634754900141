import axiosClient from "services/axios";

export const colorList = () => axiosClient.get("colors");
export const colorCreate = (data) => axiosClient.post("colors", data);
export const colorUpdate = (id, data) =>
  axiosClient.put(`colors/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
export const colorDelete = (id) => axiosClient.delete(`colors/${id}`);
export const colorUpdateStatus = (id) => axiosClient.get(`colors-status/${id}`);
