import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FileInput from "ui-component/FileInput";
const ColorForm = ({ handleSubmit, tableData }) => {
  const [state, setState] = useState({ ...tableData });
  const [loading, setLoading] = useState(false);
  const openSnackbar = useSnackbar();
  const navigate = useNavigate();

  const handleFormSubmit = async (values, { setFieldError }) => {
    setLoading(true);
    await handleSubmit({ ...values })
      .then((res) => {
        // openSnackbar("success", res.data.message);
        setLoading(false);
        navigate("/colors");
      })
      .catch((e) => {
        if (e.response.status == 422) {
          Object.entries(e.response.data.result).forEach(([key, value]) =>
            setFieldError(key, value[0])
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Formik onSubmit={handleFormSubmit} initialValues={state}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="english_name"
                  id="english_name"
                  value={values.english_name || ""}
                  onChange={handleChange}
                  label="Name In English"
                  helperText={touched.english_name && errors.english_name}
                  error={Boolean(touched.english_name && errors.english_name)}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="arabic_name"
                  id="arabic_name"
                  value={values.arabic_name || ""}
                  onChange={handleChange}
                  label="Name In Arabic"
                  helperText={touched.arabic_name && errors.arabic_name}
                  error={Boolean(touched.arabic_name && errors.arabic_name)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              color="primary"
              loading={loading}
              variant="contained"
              sx={{ my: 4 }}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ColorForm;
