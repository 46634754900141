// assets
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
// ==============================|| JOB WORK MENU ITEMS ||============================== //

const bannerManagement = {
    id: 'banners-management',
    title: 'Banner Management',
    type: 'group',
    children: [
        {
            id: 'banner',
            title: 'Banners',
            type: 'item',
            url: '/banners',
            icon: ViewCarouselIcon,
            breadcrumbs: false
        }
                
    ]
};

export default bannerManagement;
