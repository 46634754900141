import { LoadingButton } from "@mui/lab";
import {
    Grid,
    TextField,
} from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FileInput from "ui-component/FileInput";


const CategoryForm = ({ handleSubmit, tableData }) => {
    const [state, setState] = useState({ ...tableData });

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
        // console.log(state);
    };

    const handleFileChange = (index, file) => {
        console.log(file.id)
        setState({ ...state, [file.id]: file.files[0] });
    };


    const handleFormSubmit = async (values, { setFieldError }) => {
        setLoading(true);
        await handleSubmit({ ...values, arabic_image: state.arabic_image, english_image: state.english_image }).then((res) => {
            openSnackbar("success", res.data.message)
            setLoading(false);
            navigate("/categories");
        }).catch((e) => {
            if (e.response.status == 422) {
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])

                );

            }
            setLoading(false);
        });
    };

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={state}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="english_name"
                                    id="english_name"
                                    value={values.english_name || ""}
                                    onChange={handleChange}
                                    label="Name In English"
                                    helperText={touched.english_name && errors.english_name}
                                    error={Boolean(touched.english_name && errors.english_name)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="arabic_name"
                                    id="arabic_name"
                                    value={values.arabic_name || ""}
                                    onChange={handleChange}
                                    label="Name In Arabic"
                                    helperText={touched.arabic_name && errors.arabic_name}
                                    error={Boolean(touched.arabic_name && errors.arabic_name)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <FileInput
                                    url={values.english_image}
                                    handleChange={handleFileChange}
                                    fileName="english_image"
                                    label="English Image"
                                    helperText={touched.english_image && errors.english_image}
                                    error={Boolean(touched.english_image && errors.english_image)}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <FileInput
                                    url={values.arabic_image}
                                    handleChange={handleFileChange}
                                    fileName="arabic_image"
                                    label="Arabic Image"
                                    helperText={touched.arabic_image && errors.arabic_image}
                                    error={Boolean(touched.arabic_image && errors.arabic_image)}
                                />
                            </Grid>

                        </Grid>

                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            variant="contained"
                            sx={{ my: 4 }}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default CategoryForm;
