import { createSelector } from "reselect";

const selectProductReducer = (state) => {
  return state.product.productLists
};

export const selectProduct = createSelector(
  [selectProductReducer],
  (state) => {
    return state
  }
);