import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import { Box } from "@mui/system";
import SimpleCard from "ui-component/SimpleCard";
import BannerForm from "./BannerForm";
import { bannerCreate } from "services/api/banner";
import { styled } from "@mui/material";
const BannerCreate = () => {
  const title = "banner";
  const currentBanner = {
    english_title: "",
    arabic_title: "",
    english_image: "",
    arabic_image: "",
  };

  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const handleSubmit = (state) => {
    const formData = new FormData();
    formData.append("english_title", state.english_title);
    formData.append("arabic_title", state.arabic_title);
    formData.append("english_image", state.english_image);
    formData.append("arabic_image", state.arabic_image);

    return bannerCreate(formData);
  };
  return (
    <Container>
      <Box className="breakcrumb">
        <Breadcrumb
          routeSegments={[
            {
              name: capitalizeFirstLetter(title),
              path: "/" + title + "s",
            },
            { name: "Banner Create" },
          ]}
        />
      </Box>
      <SimpleCard title="Banner Form">
        <BannerForm
          handleSubmit={handleSubmit}
          tableData={currentBanner}
        ></BannerForm>
      </SimpleCard>
    </Container>
  );
};

export default BannerCreate;
