import { createSelector } from "reselect";

const selectCmsReducer = (state) => {
  return state.cms.cmsLists
};

export const selectCms = createSelector(
  [selectCmsReducer],
  (state) => {
    return state
  }
);