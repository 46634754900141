import CMS_ACTION_TYPES from './cms.types';

export const CMS_INITIAL_STATE = {
  cmsLists: [],
};

export const CmsReducer = (state = CMS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CMS_ACTION_TYPES.LOAD_CMS: {
      return { ...state, cmsLists: payload };
    }
    default:
      return state;
  }
};
