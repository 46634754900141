import { Box, Container } from "@mui/system";
import Breadcrumb from "ui-component/Breadcrumb";
import BannerForm from "./BannerForm";
import styled from "@emotion/styled";
import SimpleCard from "ui-component/SimpleCard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectBanner } from "store/banner/banner.selector";
import { capitalizeFirstLetter } from "utils/helper";
import { bannerUpdate } from "services/api/banner";

const BannerUpdate = () => {
  const title = "banner";

  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const dispatch = useDispatch();
  const { bannerId } = useParams();
  const bannerState = useSelector(selectBanner);

  const currentBanner = bannerState.filter((item) => item.id == bannerId);
  const handleSubmit = (state) => {
    const formData = new FormData();
    formData.append("english_title", state.english_title);
    formData.append("arabic_title", state.arabic_title);
    if (state.arabic_image instanceof File) {
      formData.append("arabic_image", state.arabic_image);
    }
    // if (state.english_image instanceof File) {
    formData.append("english_image", state.english_image);
    // }

    return bannerUpdate(bannerId, formData);
  };
  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            {
              name: capitalizeFirstLetter(title),
              path: "/" + title + "s",
            },
            { name: "Banner Update" },
          ]}
        />
        <SimpleCard title="Banner Form">
          <BannerForm
            handleSubmit={handleSubmit}
            tableData={{ ...currentBanner[0] }}
          ></BannerForm>
        </SimpleCard>
      </Box>
    </Container>
  );
};
export default BannerUpdate;
