import BANNER_ACTION_TYPES from "./banner.types";

export const BANNER_INITIAL_STATE = {
  bannerLists: [],
};

export const BannerReducer = (state = BANNER_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case BANNER_ACTION_TYPES.LOAD_BANNERS: {
      return { ...state, bannerLists: payload };
    }
    default:
      return state;
  }
};
