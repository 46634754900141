import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'auth/AuthGuard';
import Cms from './Cms';

// ==============================|| MAIN ROUTING ||============================== //

const CmsRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/cms/:cmsId',
            element: <Cms/>
        }
    ]
};

export default CmsRoutes;
