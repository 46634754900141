import axiosClient from "../axios";

export const productList = () => axiosClient.get('products');
export const productCreate = (data) => axiosClient.post('products', data);
export const productUpdate = (id, data) => axiosClient.put(`products/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const productUpdateStatus = (id) => axiosClient.get(`products-status/${id}`);
export const productDelete = (id) => axiosClient.delete(`products/${id}`);
