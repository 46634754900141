import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'auth/AuthGuard';
import Category from './Category';
import CategoryCreate from './CategoryCreate';
import CategoryUpdate from './CategoryUpdate';


// ==============================|| MAIN ROUTING ||============================== //

const CategoryRoutes = {
    path: '/',
    element: <AuthGuard> <MainLayout /> </AuthGuard>,
    children: [
        {
            path: '/categories',
            element: <Category/>
        },
        {
            path: '/categories/create',
            element: <CategoryCreate/>
        },
        { 
          path: '/categories/edit/:categoryId', 
          element: <CategoryUpdate />
        }

        
    ]
};

export default CategoryRoutes;
